/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { Container } from "react-bootstrap"

import Header from "./header"
import "../images/arrow.svg"

import "bootstrap/dist/css/bootstrap.min.css"
import "./layout.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          description
          owner
          social {
            fb: facebook
            t: twitter
            l: linkedin
          }
        }
      }
    }
  `)

  const meta = data.site.siteMetadata

  return (
    <Container fluid id="page-wrapper">
      <Header siteTitle={meta.title} />

      <main>{children}</main>
      <footer id="footer">
        <ul className="icons">
          <li>
            <a href={meta.social.t} className="icon alt fa-twitter">
              <span className="label">Follow us on Twitter</span>
            </a>
          </li>
          <li>
            <a href={meta.social.fb} className="icon alt fa-facebook">
              <span className="label">Follow us on Facebook</span>
            </a>
          </li>
          <li>
            <a href={meta.social.l} className="icon alt fa-linkedin">
              <span className="label">Connect on LinkedIn</span>
            </a>
          </li>
          <li>
            <a href={"mailto:" + meta.owner} className="icon alt fa-envelope">
              <span className="label">Send us an Email</span>
            </a>
          </li>
        </ul>
        <ul className="copyright">
          <li>© devdone. All rights reserved.</li>
          <li>
            Design: <a href="http://html5up.net">HTML5 UP</a>
          </li>
          <li>
            <a href="https://www.gatsbyjs.org">Gatsby</a>
          </li>
        </ul>
      </footer>
    </Container>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
